<template>
  <div class="">
    <div class="page-container" id="section">
      <div class="section">
        <div class="info-top" @mouseenter="handleMouse" @mouseleave="handleLeave">
          <div class="fullScreen" @mouseenter="handleMouse" @mouseleave="handleLeave" :class="{active: fullScreenTrue === true }" style="position: absolute;right: 50%; top: 4%" @click="click">
            <img src="@/assets/img/fullscreenBig.png" v-if="isFullscreen" alt="">
            <img src="@/assets/img/screenful.png" v-if="!isFullscreen" alt="">
            <!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click" />-->
          </div>
          <div class="timeYear">
            {{ nowDateList }}
            <div class="timeWeek">
              {{ nowDateWeek }}
            </div>
          </div>
          <div style="display: flex;align-items: center">
            <div class="info-box">设备:{{ formDate.name ? formDate.name : '--' }}</div>
            <div class="info-box">班组:{{ formDate.groupName ? formDate.groupName : '--' }}</div>
            <div class="info-box info-box-child">成员:{{ formDate.person ? formDate.person : '--' }}</div>
            <div class="time">
              {{ nowDate }}
            </div>
          </div>
        </div>
        <div class="center-box">
          <div class="center-left">
            <div @mouseenter="handleMouse" @mouseleave="handleLeave"  class="left-top">
              <div class="title-module1 title-module10">
                <div>时间稼动率</div>
                <div>TIME OPERATION RATE</div>
              </div>
              <div class="echart">
                <div class="text" style="position: relative">
                  <el-progress :color="'#5a92fb'" type="circle" :percentage="formDate.timeOee" />
                  <img style="position: absolute;left: 20%;width: 60%;top: 14%" src="@/assets/img/echartsBack.png" alt="">
                  <div class="echart-name">班组</div>
                </div>
                <div class="text" style="position: relative">
                  <el-progress :color="'#5a92fb'" type="circle" :percentage="formDate.timeOeeDay" />
                  <img style="position: absolute;left: 20%;width: 60%;top: 14%" src="@/assets/img/echartsBack.png" alt="">
                  <div class="echart-name">当日</div>
                </div>
              </div>
            </div>
            <div class="left-bottom">
              <div class="title-module1 title-module10">
                <div>性能稼动率</div>
                <div>PERFORMANCE OPERATION RATE</div>
              </div>
              <div class="echart">
                <div class="text" style="position: relative">
                  <el-progress :color="'#5a92fb'" type="circle" :percentage="formDate.capacityOee" />
                  <img style="position: absolute;left: 20%;width: 60%;top: 14%" src="@/assets/img/echartsBack.png" alt="">
                  <div class="echart-name">班组</div>
                </div>
                <div class="text" style="position: relative">
                  <el-progress :color="'#5a92fb'" type="circle" :percentage="formDate.capacityOeeDay" />
                  <img style="position: absolute;left: 20%;width: 60%;top: 14%" src="@/assets/img/echartsBack.png" alt="">
                  <div class="echart-name">当日</div>
                </div>
              </div>
            </div>
          </div>
          <div class="center-right">
            <div class="right-box" @mouseenter="handleMouse" @mouseleave="handleLeave">
              <div   class="text style device">
                <div class="title-module4 title-module10">
                  <div>设备状态</div>
                  <div>DEVICE STATUS</div>
                </div>
                <div class="maintain model-banck back-img">
                  <div style="color:#fff;">
                    {{ formDate.state ? formDate.state : '--' }}
                  </div>
                </div>
              </div>
              <div @mouseenter="handleMouse" @mouseleave="handleLeave" class="text water-box">
                <div class="title-module6 title-module10">
                  <div>水箱告警</div>
                  <div>TANK WARNING</div>
                </div>
                <div class="model-back">
                  <div class="text table-center" :style="{'color': formDate.ph === null ? '#4D87F8' : Number(formDate.ph) < Number(formDate.phDown) ? '#FA5744' : Number(formDate.ph) > Number(formDate.phUp) ? '#FA5744' : '#4D87F8', 'border': formDate.ph === null ? `1px solid ${'#8BB3FF'}` : !formDate.ph ? `1px solid ${'#8BB3FF'}` : (Number(formDate.ph) <= Number(formDate.phUp) && Number(formDate.ph) >= Number(formDate.phDown)) ? `1px solid ${'#8BB3FF'}` : `1px solid ${'#FA5744'}`,'background': formDate.ph === null ? '#ECF7FF' : !formDate.ph ? '#ECF7FF' : (Number(formDate.ph) <= Number(formDate.phUp) && Number(formDate.ph) >= Number(formDate.phDown)) ? '#ECF7FF' : '#FFEFEF'}">
                    <div>PH值</div>
                    <img class="ph" v-if="formDate.phDown === null || formDate.ph === null || !formDate.ph || (Number(formDate.ph) >= Number(formDate.phDown) && Number(formDate.ph) <= Number(formDate.phUp))" src="@/assets/img/phBlue.png" alt="">
                    <img  v-else class="ph" src="@/assets/img/ph.png" alt="">
                    <div>{{ formDate.ph ? Number(formDate.ph) : '--' }}</div>
                    <div>({{ formDate.phDown ? formDate.phDown : '--' }} - {{ formDate.phUp ? formDate.phUp : '--' }})</div>
                  </div>
                  <div class="text table-center" :style="{'color':formDate.wd === null ? '#4D87F8' : Number(formDate.wd) < Number(formDate.temperaturDown) ? '#FA5744' : Number(formDate.wd) > Number(formDate.temperaturUp) ? '#FA5744' : '#4D87F8', 'border': formDate.wd === null ? `1px solid ${'#8BB3FF'}` : !formDate.wd ? `1px solid ${'#8BB3FF'}` : (Number(formDate.wd) <= Number(formDate.temperaturUp) && Number(formDate.wd) >= Number(formDate.temperaturDown)) ? `1px solid ${'#8BB3FF'}` : `1px solid ${'#FA5744'}`,'background' :formDate.wd === null ? '#ECF7FF' : !formDate.wd ? '#ECF7FF' : (Number(formDate.wd) <= Number(formDate.temperaturUp) && Number(formDate.wd) >= Number(formDate.temperaturDown)) ? '#ECF7FF' : '#FFEFEF' }">
                    <div>水温</div>
                    <img v-if="formDate.temperaturDown === null || formDate.wd === null || !formDate.wd || (Number(formDate.wd) >= Number(formDate.temperaturDown) && Number(formDate.wd) <= Number(formDate.temperaturUp))" class="water" src="@/assets/img/water.png" alt="">
<!--                    <img v-if="(formDate.wd === null || (formDate.temperaturDown === null && formDate.temperaturUp === null)) ? (Number(formDate.wd) > Number(formDate.temperaturDown) && Number(formDate.wd) < Number(formDate.temperaturUp)) : true" class="water" src="@/assets/img/water.png" alt="">-->
                    <img v-else class="water" src="@/assets/img/waterRed.png" alt="">
                    <div>{{ formDate.wd ? Number(formDate.wd) : '--' }}</div>
                    <div>({{ formDate.temperaturDown ? formDate.temperaturDown : '--' }} - {{ formDate.temperaturUp ? formDate.temperaturUp: '--' }})</div>
                  </div>
                  <div class="text table-center" :style="{'color': formDate.ddl === null ? '#4D87F8' : Number(formDate.ddl) < Number(formDate.electricityDown) ? '#FA5744' : Number(formDate.ddl) > Number(formDate.electricityUp) ? '#FA5744' : '#4D87F8', 'border': formDate.ddl === null ? `1px solid ${'#8BB3FF'}` : !formDate.ddl ? `1px solid ${'#8BB3FF'}` : (Number(formDate.ddl) <= Number(formDate.electricityUp) && Number(formDate.ddl) >= Number(formDate.electricityDown)) ? `1px solid ${'#8BB3FF'}` : `1px solid ${'#FA5744'}`,'background': formDate.ddl === null ? '#ECF7FF' : !formDate.ddl ? '#ECF7FF' : (Number(formDate.ddl) <= Number(formDate.electricityUp) && Number(formDate.ddl) >= Number(formDate.electricityDown)) ? '#ECF7FF' : '#FFEFEF' }">
                    <div>电导率</div>
                    <img  v-if="formDate.electricityDown === null || formDate.ddl === null || !formDate.ddl || (Number(formDate.ddl) >= Number(formDate.electricityDown) && Number(formDate.ddl) <= Number(formDate.electricityUp))" class="equip" src="@/assets/img/equip.png" alt="">
                    <img v-else class="equip" src="@/assets/img/equipRed.png" alt="">
                    <div>{{ formDate.ddl ? Number(formDate.ddl) : '--' }}</div>
                    <div>({{ formDate.electricityDown ? formDate.electricityDown : '--' }} - {{ formDate.electricityUp ? formDate.electricityUp : '--' }})</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between">
              <div class="left-bottom text maintain-work-left">
                <div class="title-module5 title-module10">
                  <div>保养工作情况</div>
                  <div>MAIN TENANCE STATUS</div>
                </div>
                <div class="maintainWeek">
                  <div class="title-text">未完成保养单</div>
                    <div v-if="formDate.noComplete.length > 0" class="main-img-top">
                      <div style="display: inline-block" class="main-img" v-for="(item, index) in formDate.noComplete" :key="index">
                        <!--                      {{item}}-->
                        <img v-if="item === 1" src="@/assets/img/ri.png" alt="">
                        <img v-if="item === 2" src="@/assets/img/zhou.png" alt="">
                        <img v-if="item === 3" src="@/assets/img/yue.png" alt="">
                        <img v-if="item === 4" src="@/assets/img/bannian.png" alt="">
                        <img v-if="item === 5" src="@/assets/img/nian.png" alt="">
                      </div>
                    </div>
                  <div class="title-text" v-else style="line-height: 84px">暂无数据</div>
                  <div class="title-text">进行中保养单情况</div>
                  <div style="display: flex;justify-content: space-between;">
                    <div class="text maintain-style ">
                      <div class="title-text">
                        保养类型
                        <div class="style-main">{{ formDate.current ? formDate.current : '--' }}</div>
                      </div>
                      <div class="title-text title-text-point">
                        未完成点位
                        <div>{{ formDate.noCompleteDrop ? formDate.noCompleteDrop : '--' }}</div>
                        <div class="border-line"></div>
                        总点位
                        <div>{{ formDate.allDrop ? formDate.allDrop : '--' }}</div>
                      </div>
                    </div>
                    <div class="text maintain-detailed">
                      <div class="title-text">未完成保养清单</div>
                      <div class="text maintain-text">
                        <div v-if="formDate.noCompleteList.length > 0" style="text-align: left">
                          <div class="maintain-text-name" style="display: inline-block;width: 50%;" v-for="(item,index) in formDate.noCompleteList" :key="index">
                            <div>{{ item }}</div>
                          </div>
                        </div>
                        <div v-else style="width: 100%;font-size: 18px;padding: 10px 0;">暂无数据</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text maintain-work-right">
                <div class="left-bottom">
                  <div class="title-module title-module10">
                    <div>色彩质量</div>
                    <div>COLOR QOUALITY</div>
                  </div>
                  <div class="model-back" style="border-top: 1px solid #8BB3FF; display: block; padding: 0;position: relative">
                    <div>
                      <div class="text panel-score">最近一次评测</div>
                      <chartsPanel
                          v-if="formDate.result"
                          style="padding-bottom:30px;margin-bottom: 30px"
                      :id="'panel'"
                          :totalTop="formDate.result"
                      >
                      </chartsPanel>
                      <div v-else class="height-310px">
                        <img src="@/assets/img/echartsResult.png" alt="">
                      </div>
                    </div>
                      <div class="text panel-time">评测时间: {{ formDate.date ? formDate.date : '--' }}</div>
                  </div>
                </div>
               <div class="left-bottom">
                 <div class="title-module title-module10">
                   <div>维修工作情况</div>
                   <div>MAINTENANCE STATUS</div>
                 </div>
                 <div class="model-back service">
                   <div>
                     <div class="text service-name service-back">报修时间</div>
                     <div class="text service-center service-back">{{ formDate.time ? formDate.time : '--' }}</div>
                   </div>
                   <div >
                     <div class="text service-name-person service-name">维修人员</div>
                     <div class="text service-center service-name-person">{{ formDate.repairPerson ? formDate.repairPerson : '--' }}</div>
                   </div>
                 </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import chartsPanel from '@/components/Charts/chartsPanel';
import screenfull from "screenfull"; // 线性组件
import cookies from "@/utils/cookies";
export default {
name: "equipmen",
  components: {chartsPanel},
  data() {
    return {
      nowDate:'',
      nowDateList: '',
      nowDateWeek: '',
      fullScreenTrue: false,
      isFullscreen: true,
      formDate: {
        noComplete: [],
        noCompleteList: []
      },
      show: true
    }
  },
  computed: {
  },
  created() {
    this.formDate.timeOee = 0
    this.formDate.timeOeeDay = 0
    // if (cookies.get('teamId') === '128812f8-354f-470f-b8fa-c33ff62b51d1') {
    //   this.formDate.name = '印刷机1号'
    //   this.formDate.groupName = '1号机A班'
    //   this.formDate.person = 'alexwangs,张希玉,张在杰,印工社'
    //   this.getValue()
    //   this.formDate.capacityOee = 54.45
    //   this.formDate.capacityOeeDay = 65.27
    //   this.formDate.state = '保养'
    //   this.formDate.ph = 5.9
    //   this.formDate.phDown = 4.7
    //   this.formDate.phUp = 5.7
    //   this.formDate.temperaturDown = 9
    //   this.formDate.wd = 9.2
    //   this.formDate.temperaturUp = 13
    //   this.formDate.electricityDown = 700
    //   this.formDate.ddl = 800
    //   this.formDate.electricityUp = 1300
    //   this.formDate.noComplete = [1,2]
    //   this.formDate.noCompleteList = []
    //   this.formDate.current = '周保养'
    //   this.formDate.noCompleteDrop = 2
    //   this.formDate.allDrop = 50
    //   this.formDate.result = 89.5
    //   // this.formDate.date = this.nowDateList
    //   this.formDate.current = '周保养'
    //   this.formDate.current = '周保养'
    //   this.formDate.noCompleteList = [
    //       '输纸单元', '印刷单元'
    //   ]
    // } else {
      this.getList()
    // }
    this.getTime()
    this.currentTime()
  },
  mounted() {

  },
  methods: {
    handleMouse(event) {
      if (event.clientY < 530){
        this.fullScreenTrue = true
      }
    },
    handleLeave() {
      this.fullScreenTrue = false
    },
    click() {
      if (this.isFullscreen) {
        this.isFullscreen = false
      } else {
        this.isFullscreen = true
      }
      let element = document.getElementById("section"); //指定全屏区域元素
      screenfull.toggle(element); //全屏显示
    },
    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${hour}:${minute}:${second}`;
      this.nowDateList = `${year}.${month}.${day}`;
      // if (cookies.get('teamId') === '128812f8-354f-470f-b8fa-c33ff62b51d1') {
      //   this.formDate.date = this.nowDateList
      //   // this.getValue()
      // }
      this.nowDateWeek = `${weekArr[week]}`
    },
    getList() {
      API.DaPingJiTai().then(res => {
        if (res.message.success) {
          // console.log(res.message.data.noComplete)
          // console.log(this.formDate.noComplete)
          this.formDate = res.message.data
          console.log(this.formDate)
        } else {
          this.$message.warning('未绑定设备，无数据！');
          this.show = false
          // clearInterval(this.getTime);
        }
      })
    },
    getValue() {
      let date = new Date();
      let hour = date.getHours(); // 时
      console.log(hour)
      if (hour <= 8) {
        console.log(11)
        this.formDate.timeOee = 5
        this.formDate.timeOeeDay = 5
      } else if(8 < hour && hour <= 9) {
        console.log('111')
        this.formDate.timeOee = 8.4
        this.formDate.timeOeeDay = 8.4
      } else if(9 < hour && hour <= 10) {
        console.log('222')
        this.formDate.timeOee = 11
        this.formDate.timeOeeDay = 11
      } else if(10 < hour && hour <= 11) {
        this.formDate.timeOee = 14.7
        this.formDate.timeOeeDay = 14.7
      } else if( 11 < hour && hour <= 12) {
        this.formDate.timeOee = 17.2
        this.formDate.timeOeeDay = 17.2
      } else if( 12 < hour && hour <= 13) {
        this.formDate.timeOee = 20
        this.formDate.timeOeeDay = 20
      } else if( 13 < hour && hour <= 14) {
        this.formDate.timeOee = 23.5
        this.formDate.timeOeeDay = 23.5
      } else if( 14 < hour && hour <= 15) {
        this.formDate.timeOee = 26.1
        this.formDate.timeOeeDay = 26.1
      } else if( 15 < hour && hour <= 16) {
        this.formDate.timeOee = 29
        this.formDate.timeOeeDay = 29
      } else if( 16 < hour && hour <= 17) {
        this.formDate.timeOee = 32.6
        this.formDate.timeOeeDay = 32.6
      }else if( 17 < hour && hour <= 18) {
        this.formDate.timeOee = 35
        this.formDate.timeOeeDay = 35
      }else if( 18 < hour && hour <= 19) {
        this.formDate.timeOee = 38.3
        this.formDate.timeOeeDay = 38.3
      }else if( 19 < hour) {
        this.formDate.timeOee = 42
        this.formDate.timeOeeDay = 42
      }
    },
    getTime() {
      setInterval(()=>{
        // if (cookies.get('teamId') === '128812f8-354f-470f-b8fa-c33ff62b51d1'){
        //   this.getValue()
        // }else {
          if (this.show !== false) {
            this.getList()
          }
        // }
      },1000 * 40 )
    },
    currentTime(){
      setInterval(()=>{
        this.getDate()
      },1000)
    },
  }
}
</script>

<style scoped>
.page-container {
  padding: 0;
  background: linear-gradient(180deg, #FFFFFF 0%, #E4F4FE 0%);
}
.section {
  padding: 40px;
  background: rgba(255,255,255,0);
}
.time-img {
  width: 20px;
  margin-right: 25px;
}
.info-top {
  display: flex;
  justify-content: space-between;
  /*background-color: #f5fefe;*/
  /*padding: 10px 5px;*/
  margin-bottom: 35px;
  align-items: center;
}
.center-box {
  display: flex;
}
.center-left {
  display: inline-block;
  width: 32%;
  margin-right: 30px;
}
.center-right {
  display: inline-block;
  width: calc(100% - 32% - 30px);
}
.left-top {
  /*border: 1px solid #dcdddd;*/
  /*background-color: #f5fefe;*/
  margin-bottom: 25px;
}
.left-bottom {
  /*border: 1px solid #dcdddd;*/
  /*background-color: #f5fefe;*/
}
.title {
font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #dcdddd;
}
.title-little {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #dcdddd;
}
.title-text {
  font-size: 18px;
  padding: 16px;
  border-bottom: 1px solid #8BB3FF;
  color: #333;
}
.title-text img{
  width: 400px;
}
.main-img img {
  width: 82px;
  margin-right: 20px;
}
.main-img:last-child img{
  margin-right: 0
}
.title-text-img {
  display: flex;
  justify-content: space-evenly;
}
.title-text-point {
  border: 0;
  padding: 9px 23px;
}
.title-text-point div{
  color: #4985F6;
  font-size: 32px;
  margin-top: 22px;
  line-height: 37px;
}
.border-line {
  width: 170px;
  border-bottom: 1px solid #b9cef7;
  margin-bottom: 9px !important;
  margin-top: 0 !important;
  padding: 9px 0 0;
}
.style-main {
  color: #3173F1;
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 4px;
}
.text{
  text-align: center;
  display: inline-block;
}
.style {
  /*background-color: #f5fefe;*/
  /*border: 1px solid #dcdddd;*/
}
.right-box {
  margin-bottom: 15px;
  display: flex;
}
.device {
  width: 29%;
  margin-right: 30px;
}
.echart {
  padding: 50px 0 35px;
  display: flex;
  justify-content: space-around;
  background-color: rgb(242, 250, 254);
  box-shadow: 0px 0px 13px 0px #cae2f2;
  /*background: linear-gradient(180deg, #FFFFFF 0%, #E4F4FE 0%)*/
}
.model-banck {
  padding: 20px 0;
  background-color: rgb(242, 250, 254);
  box-shadow: 0px 0px 13px 0px #cae2f2;
}
.model-back {
  padding: 20px 15px;
  background-color: rgb(242, 250, 254);
  box-shadow: 0px 0px 13px 0px #cae2f2;
  display: flex;
  justify-content: space-around;
}
.echart-name {
  font-size: 16px;
  padding: 45px 0 0;
  margin: 20px 0 5px;
  color: #333;
}
.water-box {
  width: calc(100% - 29% - 30px);
  /*border: 1px solid #dcdddd;*/
}
.maintain {
  font-size: 24px;
  font-weight: bold;
  /*padding: 40px 0;*/
}
.table-center {
  width: 29%;
  border: 1px solid #8BB3FF;
  border-radius: 9px 9px 9px 9px;
  box-shadow: 3px 3px 3px 0px #cae2f2;
}
.table-center div{
  /*border-bottom: 1px solid #dcdddd;*/
  /*padding: 14px 0;*/
}
.table-center div:first-child{
  font-size: 30px;
  /*color: #4D87F8;*/
  padding: 15px 0 10px;
}
.table-center div:nth-child(3){
  font-size: 48px;
  /*color: #4D87F8;*/
  padding: 10px 0;
}
.table-center div:last-child{
  font-size: 16px;
  /*color: #4D87F8;*/
  padding: 0px 0 15px;
}
.maintain-work-left {
  width: 65%;
  margin-right: 30px;
}
.maintain-work-right {
  width: 37%;
}
.maintain-style {
  width: 35%;
  border-right: 1px solid #8BB3FF;
}
.maintain-detailed {
  width: calc(100% - 35%);
}
.maintain-text {
  border-right: 1px solid #8BB3FF;
  width: 100%;
  text-align: left;
}
.maintain-text:last-child{
  border: 0;
}
.maintain-text div{
  border-bottom: 1px solid #8BB3FF;
  /*padding: 17px 0;*/
  width: 100%;
  display: inline-block;
  text-align: center;
}
.maintain-text div:nth-child(2n-1) {
  border-right: 1px solid #8BB3FF;
}
.maintain-text div:nth-child(9) {
  border-bottom: 0;
}
.maintain-text div:last-child{
  border-right: 0px solid #8BB3FF;
  border-bottom: 0;
}
.maintain-text-name {
  display: inline-block;width: 100%;padding: 13px 0;
  border-bottom: 1px solid #8BB3FF !important;
  font-size: 18px;
}
.style-number {
  border-bottom: 1px solid #8BB3FF;
  padding: 10px 0;
}
.style-number {
  font-size: 24px;
}
.number {
  /*border: 1px solid #dcdddd;*/
  position: absolute;
  bottom: -20%;
  right: 0;
  font-size: 16px;
  padding: 3px 10px;
}
.time {
  align-items: end;
  font-size: 24px;
  color: #3173F1;
}
.timeYear {
  align-items: end;
  font-size: 20px;
  color: #333;
}
.timeWeek {
  align-items: end;
  font-size: 20px;
  color: #333;
}
.info-box {
  font-size: 16px;
  color: #3173F1;
  padding: 16px 30px;
  border: 1px solid #8BB3FF;
  background-color: #fff;
  box-shadow: -1px 1px 8px 0px #6790DD;;
  margin-right: 30px;
}
.info-box-child{
  margin-right: 60px;
}
.title-module {
  background-image: url("~@/assets/img/backTitle3.png");
  background-size: cover;
  background-position: center;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title-module1{
  background-image: url("~@/assets/img/4.png");
  background-size: cover;
  background-position: center;
  height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.title-module6 {
  background-image: url("~@/assets/img/2.png");
  background-size: cover;
  background-position: center;
  height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title-module4 {
  background-image: url("~@/assets/img/6.png");
  background-size: cover;
  background-position: center;
  height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title-module10 div:first-child {
  margin-left: 16px;
  color: #333;
  font-size: 20px;
  font-weight: bold;
}
.title-module10 div:last-child {
  color: #3173F1;
  font-size: 12px;
}
.title-module5 {
  background-image: url("~@/assets/img/1.png");
  background-size: cover;
  background-position: center;
  height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-progress-circle {
  width: 215px !important;
}
::v-deep .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text{
  top: 113px;
}
.back-img {
  position: relative;
  /*background-image: url("~@/assets/img/back-img.png");*/
  /*width: 190px;*/
  background-size: cover;
  background-position: center;
}
.back-img img{
  position: absolute;
  width: 190px;
  top: 20px;
  left: 22%;
}
.back-img div {
  text-align: center;
  width: 190px;
  height: 216px;
  background-image: url("~@/assets/img/back-img.png");
  background-size: cover;
  margin: auto;
  line-height: 216px;
}
.ph{
  width: 39px;
}
.water{
  width: 38px;
}
.equip {
  width: 18px;
}
.maintainWeek {
  background-color: rgb(242, 250, 254);
  box-shadow: 0px 0px 13px 0px #cae2f2;
  border: 1px solid #b8d8ed;
}
.panel-score {
  font-size: 18px;
  color: #333;
  padding-top: 20px;
}
.panel-time {
  font-size: 16px;
  color: #333;
  padding: 12px 0;
  width: 100%;
  border: 1px solid #8BB3FF;
  position: absolute;
  bottom: 0;
  left: 0;
}
.service {
  border: 1px solid #8BB3FF;
  display: block;
  text-align: left;
  padding: 0;
  font-size: 18px;
}
.service-name {
  width: 35%;
  padding: 13px;
}
.service-name {
  border-right: 1px solid #8BB3FF;
  border-bottom: 1px solid #8BB3FF;
}
.service-back{
  background-color: #ecf7ff;
}
.service-center {
  padding: 14px;
text-align: center;
  width: 65%;
}
.service-name-person:first-child {
  border-bottom: 0;
}
.service-name-person {
  padding: 32px 0;
  text-align: center;
}
.service-back:last-child{
  border-bottom: 1px solid #8BB3FF;
}
.fullScreen {
  display: none;
}
.active {
  display: block;
}
.height-310px{
  height: 310px;
}
.height-310px img {
  margin-top: 50px;
  width: 210px;
}
.main-img-top {
  border-bottom: 1px solid #8BB3FF;padding: 16px 0
}
::v-deep .el-progress-bar__outer {
  background-color: #cae2f2;
}
::v-deep .el-progress path:first-child {
  stroke: #cae2f2;
}
::v-deep .el-progress__text {
  font-size: 30px !important;
}
</style>
